<script>
export default {
  name: "AppAlertPlate",

  props: {
    color: {
      type: String,
    },

    backgroundColor: {
      type: String,
    },
  },

  computed: {
    accentColor() {
      return {
        color: this.color,
      };
    },

    borderColor() {
      return {
        border: `1px solid ${this.color}`,
      };
    },

    background() {
      return {
        background: this.backgroundColor,
      };
    },

    svgColor() {
      return {
        "--accent-color": this.color,
      };
    },
  },
};
</script>

<template>
  <div :style="[borderColor, background]" class="alert-plate">
    <div class="alert-plate__header">
      <div :style="accentColor" class="alert-plate__title">
        <svg
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 16.4355V16.4444M12 7.55556V13.7778M20 12C20 16.4183 16.4183 20 12 20C7.58172 20 4 16.4183 4 12C4 7.58172 7.58172 4 12 4C16.4183 4 20 7.58172 20 12Z"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
            stroke="#E40101"
            :style="svgColor"
          />
        </svg>
        <slot name="title"></slot>
      </div>

      <div class="alert-plate__description">
        <slot name="description"></slot>
      </div>
    </div>
    <div :style="accentColor" class="alert-plate__warn-description">
      <slot name="warn-description"></slot>
    </div>
  </div>
</template>

<style scoped lang="scss">
.alert-plate {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  padding: 16px 24px;
  border-radius: 24px;
  position: relative;

  @include font-description-semibold-0;

  @media (max-width: $tablet-width) {
    align-items: flex-start;
    flex-direction: column;
  }

  @media (max-width: $mobile-width) {
    font-size: 12px;
    line-height: 16px;
  }

  svg {
    margin-right: 12px;

    path {
      stroke: var(--accent-color);
    }
  }

  &__header {
    display: flex;
    flex-direction: column;
    padding-right: 32px;
    flex: 1;
  }

  &__title {
    display: flex;
    align-items: center;
    margin-bottom: 8px;

    &:not(:has(*)) {
      margin-bottom: unset;

      svg {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        left: 3px;
      }
    }
  }

  &__description {
    @include font-subtitle-medium;

    > * {
      display: flex;
      flex-direction: column;
      gap: 5px 0;

      line-height: 20px;

      @media (max-width: $mobile-width) {
        font-size: 12px;
        line-height: 16px;
      }
    }
  }

  &__warn-description {

    &:has(*) {
      flex: 1;
      border-left: 1px solid var(--grey-50);
      padding-left: 32px;
      line-height: 20px;

      @media (max-width: $tablet-width) {
        border-left: unset;
        padding-left: unset;
        margin-left: unset;

        margin-top: 16px;
        padding-top: 16px;
        border-top: 1px solid var(--grey-50);
      }

      @media (max-width: $mobile-width){
        line-height: 16px;
      }
    }
  }
}
</style>
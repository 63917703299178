<script>
export default {
  name: "AppInputFile",
  props: {
    label: String,
    temp_id: Number,
  },

  data() {
    return {
      files: [],
      upLoadedFiles: [],
      isDragging: false,
      showProgress: false,
    };
  },

  computed: {
    windowSize() {
      return document.documentElement.clientWidth
    },

    showFiles(){
      return this.windowSize > 743 ? this.upLoadedFiles : this.upLoadedFiles.slice(1, 6)
    }
  },

  methods: {
    selectFiles() {
      this.$refs.fileInput.click()
    },

    uploadFile(files) {
      if (files.length === 0) return;
      const formData = new FormData()
      formData.append(files[0].name, files[0])
      formData.append("temp_id", this.temp_id)

      this.showProgress = true

      for (let i = 0; i < files.length; i++) {
        if (!this.files.some((e) => e.name === files[i].name)) {
          this.files.push(files[i].name)
        }
      }

      this.$postapi(this.$address + this.$getters.ftpposttest.uri, formData, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }).then(() => {
        for (let i = 0; i < files.length; i++) {
          if (!this.upLoadedFiles.some((e) => e.name === files[i].name)) {
            this.upLoadedFiles.push(files[i].name)
          }
        }
        this.$emit('fileUploaded', this.upLoadedFiles)
        this.showProgress = false
        this.files = []
      });
    },

    onSelectFile(e) {
      const files = e.target.files
      this.uploadFile(files)
    },

    deleteFile(idx) {
      this.upLoadedFiles.splice(idx, 1)
    },

    onDragOver(e) {
      this.isDragging = true
      e.dataTransfer.dropEffect = "copy"
    },

    onDragLeave() {
      this.isDragging = false
    },

    onDrop(e) {
      this.isDragging = false
      const files = e.dataTransfer.files
      if (files.length) {
        const file = files[0]; // Берем первый файл
        const extension = file.name.split('.').pop();
        const fileInfo = {
          name: file.name,
          size: file.size, // Размер файла в байтах
          extension: extension, // Расширение файла
        };

        console.log(fileInfo)
      }
      console.log(files)
      this.uploadFile(files)
    },
  },
};
</script>

<template>
  <div class="drop-file">
    <p class="drop-file__title">{{ label }}</p>
    <div
      class="drop-file__area"
      
    >
      <div
      :class="isDragging ? 'drop-file__main--progress' : ''"
        class="drop-file__main"
        @dragover.prevent="onDragOver"
        @dragleave.prevent="onDragLeave"
        @drop.prevent="onDrop"
      >
        <div v-if="!isDragging">
          <p v-if="windowSize > 1200" class="drop-file__main-description">
            Перетащите файл или
            <span class="drop-file__main-link" @click="selectFiles"
              >загрузите с диска</span
            >
          </p>
          <span v-else class="drop-file__main-link" @click="selectFiles"
            >загрузите с диска</span
          >
        </div>
        <div v-else>
          <p class="drop-file__main-description">Разместите файл здесь</p>
        </div>

        <input
          class="drop-file__input"
          type="file"
          name="file"
          ref="fileInput"
          multiple
          @change="onSelectFile"
        />
      </div>
      <div class="drop-file__items">
        <div class="drop-file__items-load" v-if="showProgress">
          <div
            class="drop-file__item"
            v-for="(file, index) in files"
            :key="index"
          >
            <p class="drop-file__item-name">{{ file }}</p>
            <div
              :class="showProgress ? 'drop-file__loader--progress' : ''"
              class="drop-file__loader"
            >
              <img
                src="../../assets/img/icons/cancel-grey.svg"
                alt="Close icon"
              />
            </div>
          </div>
        </div>
        <div class="drop-file__items-load drop-file__items-load--loaded">
          <div
            class="drop-file__item"
            v-for="(file, index) in showFiles"
            :key="index"
          >
            <p class="drop-file__item-name">{{ file }}</p>
            <img
              src="../../assets/img/icons/cancel.svg"
              alt="Close icon"
              @click="deleteFile(index)"
            />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped lang="scss">
input {
  display: none;
}

.drop-file {


  &__title {
    color: var(--grey-70);
    font-family: "Montserrat", sans-serif;
    font-size: 12px;
    font-weight: 500;
    line-height: 16px;
    margin-bottom: 4px;
  }

  &__main {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 144px;

    border-radius: 10px;
    background-image: url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill: none; stroke: %23C1CDD6; stroke-width: 4; stroke-dasharray: 14 15.5; stroke-linejoin:round'/></svg>");

    transition: 0.2s;

    @media (max-width: $tablet-width){
      height: 158px;
    }

    &--progress {
      background-image: url("data:image/svg+xml;utf8,<svg width='100%' height='100%' xmlns='http://www.w3.org/2000/svg'><rect width='100%' height='100%' style='fill: none; stroke: %23305CA8; stroke-width: 4; stroke-dasharray: 14 15.5; stroke-linejoin:round'/></svg>") !important;
      background: var(--blue-10);
    }

    &-description {
      color: var(--grey-50);
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.7px;
      pointer-events: none;
    }

    &-link {
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      font-weight: 500;
      line-height: 16px;
      letter-spacing: 0.7px;
      color: var(--blue-70);
      pointer-events: all;
      cursor: pointer;
      transition: 0.2s;

      &:hover {
        opacity: 0.8;
      }
    }
  }

  &__items {
    position: relative;

    &-load {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column-reverse;
      gap: 17px 64px;
      max-height: 200px;
      margin-top: 20px;
      margin-bottom: 17px;
      justify-content: flex-start;

      @media (max-width: $mobile-width){
        max-height: unset;
      }

      &--loaded {
        flex-direction: column;
        margin-bottom: unset;

        @media (max-width: $mobile-width){
          flex-direction: column-reverse;
        }
      }
    }
  }

  &__item {
    max-width: 45%;
    display: flex;
    gap: 0 8px;
    align-items: center;
    justify-content: space-between;

    position: relative;
    z-index: 100;

    @media (max-width: $mobile-width){
      max-width: unset;
    }

    &-name {
      color: var(--grey-60);
      font-family: "Montserrat", sans-serif;
      font-size: 14px;
      font-weight: 400;
      letter-spacing: 0.14px;
    }
  }

  &__loader {
    position: relative;
    min-width: 24px;
    min-height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;

    &--progress {
      &:before {
        position: absolute;
        content: "";
        border: 1px solid var(--grey-20);
        border-top: 1px solid var(--grey-60);
        border-radius: 50%;
        width: 100%;
        height: 100%;
        animation: spin 2s linear infinite;
      }
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
}
</style>